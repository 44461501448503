/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
@import "sass-lib";

body {  
    color: #000;
    font-size: 12px;
    font-family: "Open Sans"; 
    position: relative;
    overflow-x: hidden;
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }
/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper {width: 1425px;margin: 0 auto;}
.wrapper-2 {width: 1168px;margin: 0 auto;}
.wrapper-fluid { width: 100%; position: relative;
    &:after{clear:both;display: block;content:" "}
}
.wrapper-side {width: 1659px;}
hr {
    &.grey { background-color: $grey; height: 1px; border: none; }
}

/* global
----------------------------------------------------------------------------------------------*/
.btn { padding: 14px 43px; font-size: 14px; @include borderRadius(30px); display: table; line-height: 18px;
    &.btn-red { background-color: $red; color: $white; }
}

span {
    &.search { width: 14px; height: 13px; background-image: url('../img/material/search.png'); display: inline-block; }
    &.arrow-right , &.arrow-right-white , &.arrow-left , &.arrow-left-white { display: inline-block; margin-left: 16px; top: 3px; width: 37px; height: 16px; position: relative; }
    &.arrow-right , &.arrow-left { background-image: url('../img/material/arrow-right.png'); }
    &.arrow-right-white , &.arrow-left-white { background-image: url('../img/material/arrow-right-white.png'); }
    &.arrow-left , &.arrow-left-white { @include rotation(180deg); }
    &.rate-up , &.rate-down { position: relative; float: left; width: 20px; height: 19px; margin-right: 8px; }
    &.rate-up { background-image: url('../img/material/arrow-rate-up.png'); }
    &.rate-down { background-image: url('../img/material/arrow-rate-down.png'); }
    &.soc {
        display:block;position:relative; width: 20px; height: 18px; background-repeat: no-repeat;
        &.ig { background-image: url('../img/material/socmed-instagram.png'); }
        &.fb { background-image: url('../img/material/socmed-facebook.png'); }
        &.li { background-image: url('../img/material/socmed-linkedin.png'); }
        &.tw { background-image: url('../img/material/socmed-twitter.png'); }
        &.yt { background-image: url('../img/material/socmed-youtube.png'); }
    }
    &.share-socmed { position: relative; width: 19px; height: 18px; background-repeat: no-repeat; display: inline-block;
        &.fb { background-image: url('../img/material/share-fb.png'); }
        &.tw { background-image: url('../img/material/share-tw.png'); }
        &:hover {
            &.fb { background-image: url('../img/material/share-fb-hover.png'); }
            &.tw { background-image: url('../img/material/share-tw-hover.png'); }
        }
    }
}
a {
    font-size: 14px; cursor: pointer;
    &.more { font-weight: bold; color: $red;
        &.more-white { color: $white !important; }
    }
    &.red { color: $red; }
}

.mainbanner .slick-slide img {width: 100%;}

/* header
----------------------------------------------------------------------------------------------*/
header {
    position: fixed; top: 0px; right: 0px; left: 0px; z-index: 10;
    .search-mobile-container { display: none; }
    .hamburger-container { display: none; }
    .top { background-color: $red; color: $white; padding: 10px 0px; 
        .search-container { display: inline-block; margin-right: 48px;position: relative;top: 2px; cursor: pointer; } 
        .lang {
            display: inline-block;
            li { display: inline-block; 
                a { color: $white; }
                &.active {
                    a { font-weight: bold; }
                }
                &:last-child {
                    &::after { display:none; }
                }
                &::after { content: "|"; position: relative; margin: 0px 13px; top: -1px; }
            }
        }
    }
    .bottom {
        .wrapper { padding: 25px 0px; background-image: url('../img/material/border-header.png'); background-repeat-y : no-repeat; background-repeat: repeat-x; background-position: bottom; }
        .menu {
            li { float: left; padding: 29px 24px; position: relative;
                a { font-size: 14px; text-transform: uppercase; color: #000; font-weight: 600;  }
                ul { display: none; 
                    li {
                        a { font-weight: normal; }
                        &:hover {
                            a { font-weight: bold; color: $red; }
                        }
                    }
                }
            }
        }
    }
}

/* middle
----------------------------------------------------------------------------------------------*/
@media screen and (min-width:1441px){
    .hide-big { display: none; }
}
figure { margin: 0px; padding: 0px; }
input {  }
form {
    @include boxSizing(border-box);
    div.box {
        position: relative; padding: 16px 0px; @include boxSizing(border-box);
        &.select {
            position: relative; display: inline-block; padding: 0px; margin-bottom: 20px;
            span.arrow-select { position: absolute; right: 0px; width: 12px; height: 11px; top: 12px; background-image: url('../img/material/arrow-select-plain.png'); }
        }
        &.active , &.filled {
            span.title  { font-size: 10px; top: 7px; }
        }
        select {
            position: relative; padding-right: 31px; cursor: pointer;
            &.plain { border: none; font-size: 15px; font-weight: bold; color: $grey2; padding-left: 0px; }
        }
        span.title { position: absolute; top: 12px; left: 0px; display: block; font-size: 16px; @include transition(all .25s ease-out); }
        input {
            border: none; border-bottom: 1px solid $grey; width: 100%; @include boxSizing(border-box); font-size: 24px; font-weight: lighter; font-family: "Open Sans";
        }
    }
    .button { margin-top: 30px; display: block; position: relative; 
        &::after { content:''; display: block; clear: both; }
    }
    button { @include appearance(none); border: none; padding: 17px 42px; @include borderRadius(30px); font-weight: normal; font-family: "Open Sans"; font-size: 12px; cursor: pointer;
        &.red { background-color: $red; color: $white; }
    }
}
div {
    &.breadcrumb-submenu { position: absolute; top: 0px; border-bottom : 1px solid #f1f1f1; width: 100%; 
        .wrapper {
            span { display: inline-block; }
            .left {
                float: left; width: 50%;
                span.mainPage { font-size: 14px; color: $grey4; margin: 25px 0px; }
            }
            .right {
                float: left; width: 50%; text-align: right;
                span.currentPage { font-weight: bold; font-size: 14px; color: $red; margin: 25px 0px; }
                span.arrow { cursor: pointer; position: relative; width: 15px; height: 11px; background-image: url('../img/material/arrow-slider-down.png'); margin-left: 17px; @include transition(all .26s     ease-in-out);  
                    &.active { @include rotation(180deg); }
                }
            }
        }
    }
    &.search-txt-container {
        display: none; z-index: 1001; position: fixed; top: 37px; left: 0px; right: 0px; width: 100%; min-height: 123px; background-color: $white;
        form {
            margin: 32px;
            span.search { position: relative; top: 6px; width: 32px; height: 32px; background-image: url('../img/material/search-big.png'); }
            input { border: none; font-family: "Open Sans"; font-size: 28px; font-weight: lighter; font-style: italic; }
        }
    }
    &.big-text-bg { position: absolute; top: 0px; z-index:1;
        &.fr { right: 0; }
        &.fl { left: 0; }
        figure{opacity: 0.1;}
    }
    &.col-2 { position: relative; width: 50%; float: left; }
    &.download-list-container {
        margin-top: 32px; margin-bottom: 30px; display: block; position: relative;
        .download-list {
            display: block; position: relative; margin-bottom: 20px;
            p { float: left; max-width: 70%; line-height: 24px; }
            a { position:relative; float: right; max-width: 130px; color: $black; 
                &::after { position:absolute; left: 0px; right: 0px; bottom: -2px; height: 1px; background: $black; content: ''; }
            }
        }
    }
    &.downlist-container {
        position: relative;
        .downlist { width: 50%; float: left; position: relative; @include boxSizing(border-box); margin-bottom: 68px;
            .image { width: 50%; float: left; @include boxSizing(border-box); }
            .content { position: absolute; padding-left: 20px; padding-top: 35px; top: 0px; bottom: 0px; right: 0px; width: 50%; @include boxSizing(border-box); padding-right: 20px;
                &::before { content:''; position: absolute; top: 0px; left: 20px; width: 55px; height: 3px; background-color: $red; }
                a.title { font-size: 16px; line-height: 24px; font-weight: bold; color: $black; }
                div.downlink-abs { position: absolute; bottom: 15px; }
            }
            &:nth-child(odd) { padding-right: 12px; }
            &:nth-child(even) { padding-left: 12px; }
            &::after { display:block; content: ''; clear: both; }
        }
        &::after { display:block; content: ''; clear: both; }

    }
    &.downlist2-container {
        position: relative;
        .downlist2 {
            float: left; width: 48%; @include boxSizing(border-box); border: 1px solid $grey; padding: 34px; margin-bottom: 4%;
            &:nth-child(odd) { margin-right: 2%; }
            &:nth-child(even) { margin-left: 2%; }
            .title-container { position: relative; padding: 35px 0px 77px 0px; 
                &::after { content:''; position: absolute; top: 0px; left: 0px; width: 55px; height: 3px; background-color: $red; }
                a { font-size: 16px; line-height: 24px; color: $black; }
            }
        }
        &::after { display:block; content: ''; clear: both; }
    }
    &.box-form {
        margin-bottom: 28px;
        span { font-size: 16px; color: $grey2; padding-right: 34px; }
        select { @include appearance(menulist); cursor: pointer; font-size: 16px; padding: 20px 70px 20px 20px; border: 1px solid $grey; color: $grey2; 
        }

    }
    &.pagination {
        display: block; margin-top: 120px;
        &.right { text-align: right; }
        &.nomargin { margin-top: 0px !important; }
        ul {
            li { float: left; cursor: pointer; padding-left: 25px !important;
                &::before { display: none; }
                &.active , &:hover {
                    a { font-weight: bold; }
                }
                a { color: $red; font-size: 18px;
                    span {  }
                }
            }
        }
    }
}
p {
    &.text-title { position: absolute; top: 100px; font-size: 20px; text-transform: capitalize; font-style: italic;
        span.line { position: relative; display: inline-block; width: 245px; height: 1px; background-color: $red; top: -3px; }
        &.fr { right: 0;
            span.line { margin-left: 30px; }
        }
        &.fl { left: 0; 
            span.line { margin-right: 30px; top: -6px; }
        }
    }
}
span {
    &.download { position: relative; top: -4px; margin-right: 15px; float: left; width: 20px; height: 19px; background-image: url('../img/material/download.png'); }
    &.download-red { position: relative; margin-right: 15px; top: -3px; float: left; width: 16px; height: 17px; background-image: url('../img/material/download-red.png'); }
}

body {
    p { font-size: 16px; line-height: 30px; }
    table {
        width: 100%; font-size: 16px;
        thead {
            position: relative;
            &.bg-red { background-color: $red; color: $white; }
            th {
                padding: 19px 11px; text-align: left; font-weight: bold;
            }
            &::after { content: ''; display: block; height: 19px; background-color: $white; }
        }
        tbody {
            td {
                padding: 19px 11px; text-align: left;
            }
            tr {
                border-bottom: 1px solid $grey;
                &:last-child { border-bottom: 4px solid $red;
                    td { padding-bottom: 32px; }
                }
            }
        }
        &.ikhtisar {
        }
        &.hasmargin { margin-bottom: 72px; }
    }
    div {
        &.slider-2 {
            position: relative; margin:80px 0px;
            button { z-index: 10; @include appearance(none); border: none; background: transparent; position: absolute; right: 0px; bottom: 20px; border: 1px solid $black; width: 60px; height: 60px; background-color: $white; cursor: pointer; font-weight: bold; background-repeat: no-repeat; background-position: center center;
                &.slick-prev { right: 59px; background-image: url('../img/material/arrow-slider-left.png'); }
                &.slick-next { background-image: url('../img/material/arrow-slider-right.png'); }
                &:hover { background-color: $red; color: $white; 
                    &.slick-prev { background-image: url('../img/material/arrow-slider-left-hover.png'); }
                    &.slick-next { background-image: url('../img/material/arrow-slider-right-hover.png'); }
                }
            }
            .list { position: relative; 
                p.title { float: left; width: 80%; padding-top: 30px; font-size: 14px; line-height: 18px; }
            }
        }
        &.slider-3 {
            position: relative; margin-bottom: 30px;
            .image , .content { width: 47%; float: left; position: relative; @include boxSizing(border-box); }
            .image { padding-right: 30px; }
            .content {
                width: 53%; padding-top: 30px;
                p.title { font-size: 30px; line-height: 40px;  }
                &::before { content:''; position: absolute; top: 0px; left: 0px; width: 55px; height: 3px; background-color: $red; }
                .spec-list {
                    display: block; margin: 40px 0px;
                    .list {
                        position: relative; float: left; text-align: center; padding: 0px 15px;
                        span { display: block; margin-top: 10px; }
                        &:first-child { padding-left:0px; }
                    }
                    &::after { content: ''; clear: both; display: block; }
                }
            }
            &::after { content: ''; clear: both; display: block; }
        }
        &.slider-3-thumb {
            position: relative; width: 39%; left: -1px; bottom: 0px; background-color: $white; border: 1px solid $grey; @include boxSizing(border-box); margin-left: 28px; margin-bottom: 50px;
            .list {
                cursor: pointer; opacity: 0.6; @include transition(.25s all ease-out);
                &:hover { opacity: 1; }
                &.slick-current { opacity: 1; }
                img { margin: 0 auto; padding: 16px 0px; }
            }
            button {
                @include appearance(none); position: absolute; border: 1px solid $grey; bottom: -1px; width: 28px; top: -1px; opacity: 0.7; @include transition(.15s all ease-out); cursor: pointer; background-repeat: no-repeat; background-position: center;
                &.slick-prev { left: -28px; background-image: url('../img/material/arrow-slider-left-hover.png'); }
                &.slick-next { right: -28px; background-image: url('../img/material/arrow-slider-right-hover.png'); }
                &:hover { background-color: $red; opacity: 1; }
            }
            &::before { content: ''; position: absolute; top: 0px; bottom: 0px; left: -28px; right: -28px; background-color: $white; }
        }
        &.rect-big , &.rect-big-white {
            margin: 80px 0px; display: block; position: relative; padding: 101px 0px 72px; background-size: auto 100%; @include boxSizing(border-box);
            h3 { font-weight: bold; font-size: 28px; margin-bottom: 32px; }
            .content { float: left; padding-left: 90px; padding-top: 54px; max-width: 420px;
                a , a:hover , a:focus , a:active { color: $white }
                span.download { top: 3px; }
            }
            .abs-content { left: 5%; position: absolute; top: 50%; resize: vertical; transform: translateY(-50%); width: 32%; }
            .abs-content-2 { left: 8%; position: absolute; bottom: 7%; width: 32%; }
            .map , .content-right { margin-left:530px; width: 100%; max-width: 674px; @include boxSizing(border-box); height: 480px;
                iframe { width: 100%; height: 100%; }
            }
            .content-right { height: auto; margin-left: 43.5%; position: relative;
                .thank-you { position: absolute; top:0; left: 0; right: 0; bottom: 0; background-color: #fff; z-index: 5; 
                    .inner { position: absolute; top: 50%; width: 100%;left: 0px; right: 0px; transform: translateY(-50%); resize: vertical; padding: 0px 70px; @include boxSizing(border-box);
                        h4 { font-size: 24px; margin: 4px 0px; }
                        h6 { font-size: 16px; line-height: 24px; }
                    }
                }
                &.bg-grey { background-color: $grey; }
                &.bg-white { background-color: $white; }
                form {
                    padding: 60px 55px; width: 100%; @include boxShadow(13px 23px 33px rgba(0,0,0,0.3));
                }
            }
            &::after { content: ''; display: block; clear: both; }
        }
        &.rect-big { background: url('../img/material/big-rect-red.png'); background-repeat: no-repeat; background-size: auto 100%; 
            .content { color: $white; }
        }
        &.rect-big-white { padding-top: 39px; color: $black; background: url('../img/material/big-rect-white.png') no-repeat; background-size: auto 100%; }
        &.date-share {
            margin: 18px 0px 18px; display: block;
            .date , .share { float: left; width: 50%; }
            .date {
                padding-top: 2px;
            }
            .share { text-align: right; font-size: 14px; color: $grey2; 
                span.text { position: relative; top: -3px; }
                a { padding: 0px 10px; 
                    &:nth-child(2) { margin-left:30px; }
                }
            }
            .date { font-size: 14px; color: $grey2; font-style: italic; }
            &::after { content: ''; clear: both; display: block; } 
        }
        &.list-gallery {
            padding: 0% 9%; position: relative; width: 100%; @include boxSizing(border-box);
            .list { float: left; width: 25%; @include boxSizing(border-box); padding-right: 3.5%; margin-top: 25px; }
            &::after { content: ''; display: block; clear: both; }
        }
    }
    &.home {
        h6 { font-size: 18px; text-transform: uppercase; font-weight: bold; }
        h3 { font-size: 46px; }
        h1 { font-size: 46px; line-height: 60px; }
        div.trigger-anim { z-index: -1; position: absolute; top: 40%; width: 100%; height: 10px; background-color: transparent; }
        header { position: absolute; @include transition(all .2s ease-in-out); z-index: 1000;
            &.active { position: fixed; 
                .bottom {
                    background-color: $white; border-bottom: 1px solid $grey;
                    .wrapper { padding: 0px; background-image: none; }
                    .menu {
                        li { padding: 0px;
                            > a { padding: 36px 24px; display: block; }
                            ul { display: none; position: absolute; background-color: $grey5; width: 276px;
                                li {
                                    display: block; width: 100%; border-bottom: 1px solid $grey;
                                    &:hover { background-color: #e2dfdf; }
                                    a { padding: 21px 33px !important; font-size: 14px; text-transform: capitalize; }
                                }
                            }
                            &:hover , &.active { background-color:$grey5; }
                            &:hover {
                                ul { display: block; }
                            }
                        }
                    }
                    .logo {
                        img { max-height: 46px; top: 16px; position: relative; }
                    }
                }
            }
        }
        section {
            &.mainbanner { position: relative; min-height: 0; 
                .list { position: relative; height: auto;
                    .img {
                        img {display: block; width: 100%;}
                    }
                    .inner {
                        position: absolute; top: 70%; transform: translateY(-70%); resize: vertical; left: 0px; right: 0px;
                        h1 { margin-bottom: 40px; }
                    }
                }
            }
            &.about {
                position: relative; top: 50px; margin-bottom: 232px;
                .wrapper { padding-top: 180px; }
                .image { float: left; width: 50%; text-align: center; }
                .content { float: right; width: 50%; text-align: center; 
                    p { font-size: 18px; padding: 0px 70px 70px 70px; margin-top: 160px; }
                }
            }
            &.project {
                display: block; margin-bottom: 86px;
                .text-title { color: $white; 
                    span.line { background: $white; }
                }
                .bg-content { width: 1445px; height: 986px; background-color: rgba(80,2,4,0.95); position: absolute; top: 0px; left: 0px;
                    -webkit-clip-path: polygon(0px 986px,977px 975px,1445px 0px,0px 0px); clip-path: url("#clipPolygonBig");
                    img { min-height: 100%; min-width: auto; opacity: 0.2 }
                }
                .slider-project {
                    padding-top: 164px; padding-bottom: 84px;
                    .list {
                        position: relative; padding-left:396px; @include boxSizing(border-box); margin: 40px 0px;
                        img { float: right; max-width: 100%; margin:0px; position: relative; }
                        .title-desc {
                            position: absolute;left: 0px;bottom: 0px; max-width: 350px; color: $white;
                            span.border { display: block; position: relative; width: 159px; height: 10px; background: $white; }
                            h2 { font-size: 60px; line-height: 60px; font-weight: bold; margin:56px 0px; }
                            a , p { color: $white; }
                            p { font-size: 14px; line-height: 22px; opacity: 0.8; margin-bottom: 30px; }
                        }
                    }
                    .slick-dots { 
                        padding:68px 0px; float: right; position: absolute; top: 60%; resize: vertical; transform: translateY(-60%); right: -125px; 
                        &::after { position:absolute; top: 0px; bottom: 0px; right:3.3px; width: 1px; background:$red2; content: ''; }
                        button { 
                            cursor:pointer; position:relative; @include appearance(none); border: none; color: transparent; background-color: transparent; font-weight: bold; padding: 5px 30px 5px 0px; margin: 15px 0px;
                            &::after { content:''; @include borderRadius(10px); position:absolute; right: 0px; top: 6px; width: 8px; height: 8px; background: $red2; }
                        }
                        .slick-active {
                            button { 
                                color: $black; 
                                &::after { width:15px; height: 15px; right: -4px; top:4px; }
                            }
                        }
                    }
                }
                .slider-project-nav {
                    .list {
                        position: relative; color: transparent;
                        &::after { content:''; @include transition(all .2s ease-out); @include borderRadius(10px); position:absolute; right: 8px; top: 2px; width: 8px; height: 8px; background: $red2; }
                        &.slick-current {
                            color: $red; font-weight: bold;
                            &::after { width:15px; height: 15px; right: 4px; top:-2px; }
                        }
                    }
                    button {
                        cursor: pointer; @include appearance(none); background-color: transparent; border: none; width: 15px; height: 11px; position: absolute; left: 22px;
                        &.slick-prev { top: -8px; background-image: url('../img/material/arrow-slider-up.png'); }
                        &.slick-next { bottom: -8px; background-image: url('../img/material/arrow-slider-down.png'); }
                    }
                }
            }
            &.news {
                position: relative; padding-top: 94px; margin-bottom: 191px;
                .text-title { top: 50px; }
                .news-big {
                    margin-bottom: 73px;
                    .image { float: left; width: 50%; max-width: 50%; }
                    .content { float: right; width: 50%; max-width: 50%; padding: 10px 50px; @include boxSizing(border-box);
                        h4 { font-size: 26px; line-height: 38px; }
                        p.date { margin-bottom: 20px; }
                        p.desc { font-size: 16px; line-height: 30px; margin: 32px 0px; }
                    }
                }
                .news-list-container {
                    padding: 0px 0px 0px 22px; @include boxSizing(border-box);
                    .news-list { float: left; width: 27%; padding-right: 27px; @include boxSizing(border-box);
                        img { margin-bottom: 18px; max-width: 100%; }
                        h4 { font-size: 16px; line-height: 24px; max-width: 85%;margin-bottom: 35px; }
                    }
                }
                .view-all-container { float: right; }
                p.date { font-size: 12px; line-height: 18px; font-style: italic; margin-bottom: 14px; }
                a.more { font-size: 14px; color: $black; }
            }
            &.investor {
                margin-bottom: 266px;
                .wrapper-2 { padding-top: 226px; }
                h6.title { margin-bottom: 51px; }
                h6 { margin-bottom: 10px; }
                h3 { font-weight: lighter; line-height: 55px;
                    sup { position: relative; top: 3px; font-size: 28px; margin-left: 10px; }
                }
                .btn { margin:25px 0px; }
                .side-left , .side-right { padding-right: 100px; @include boxSizing(border-box); }
                .side-left { float: left; width: 50%;}
                .side-right { float: right; width: 50%;
                    h3 {font-size: 58px;}
                    .day-datetime {
                        .left { width: 50%; }
                        .right { width: 50%; text-align: right;
                            p , a { display: inline-block; }
                            p.date { margin-right: 59px; }
                        }
                    }
                    .col-rate-container {
                        margin: 43px 0px;
                        .col-rate {
                            margin-bottom: 15px;
                        }
                    }
                    p.source { margin: 10px 0px; }
                }
            }
            &.csr {
                min-height: 655px; background-image: url('../img/material/bg-csr.png'); background-size: auto 100%; margin-bottom: 185px;
                .wrapper-2 { padding-top: 201px; 
                    .content { max-width: 350px; }
                }
                img {
                    &.plant { position: absolute; left: 0px; bottom: 0px; z-index: 10; }
                    &.path-1 { position: absolute; left: 0px; right: 0px; bottom: 54px; }
                    &.path-2 { position: absolute; right: 0px; bottom: 141px; }
                }
            }
            &.career {
                background-image: url('../img/material/career-bg.png'); background-position: bottom center; background-repeat: no-repeat; 
                .text-title { top: 49px; }
                .wrapper-2 { padding: 185px 160px 0px 160px; @include boxSizing(border-box); 
                    img { margin-top: 48px; }
                }
            }
        }
    }

    &:not(.home){
        header {
            z-index: 1000;
            .bottom {
                background-color: $white; border-bottom: 1px solid $grey;
                .wrapper { padding: 0px; background-image: none; }
                .menu {
                    li { padding: 0px; position: relative;
                        > a { padding: 36px 24px; display: block; }
                        ul { display: none; position: absolute; background-color: $grey5; width: 276px;
                            li {
                                display: block; width: 100%; border-bottom: 1px solid $grey;
                                &:hover { background-color: #e2dfdf; }
                                a { padding: 21px 33px !important; font-size: 14px; text-transform: capitalize; }
                            }
                        }
                        &:hover , &.active { background-color:$grey5; }
                        &:hover {
                            ul { display: block; }
                        }
                        &.active {
                            background-color: #fff;
                            &:hover { background-color:$grey5; }
                            &::after {
                                position: absolute; border: 0px; left: 0px; right: 0; margin: auto; width: 50px; height: 3px; background-color: #000; content: ""; bottom: -1px;
                            }
                        }
                    }
                }
                .logo {
                    img { max-height: 46px; top: 16px; position: relative; }
                }
            }
        }
        footer { top: 123px; position: relative; }
        h1 { font-size: 55px; line-height: 60px; }
        h3 {
            &.desc { font-size: 26px; line-height: 40px; color: $grey3; margin: 50px 0px; }
        }
        h4 { font-weight: bold; font-size: 24px; line-height: 40px; margin-bottom: 15px;  }
        p , b , a.title-bold { font-size: 16px; line-height: 30px; margin-bottom: 30px; }
        a.title-bold { font-weight: bold; }
        b { margin-bottom: 0px; }
        a.more { font-size: 14px; color: $red; font-weight: bold; }
        div {
            &.article-title {
                width: 100%; border-bottom: 1px solid $grey;
                b { font-size: 16px; color: $grey2;  }
                ul.breadcrumb {
                    display: block; padding: 0px;
                    li { display: inline-block; position: relative; padding: 0px 41px;
                        &::before { display:none; }
                        &:first-child { padding-left: 0px; }
                        &:nth-child(n+2) {
                            &::before { content:'|'; display: block; background-color: transparent; position: absolute; left: 0px; top: 0px; }
                        }
                        &.bold {
                            a { font-weight: bolder; }
                        }
                        a { font-size: 16px; color: $grey2;  }
                    }
                }
                h1 { font-size: 52px; line-height: 70px; font-weight: bolder; margin: 30px 0px 40px; }
                form {
                    input { border: none; font-size: 52px; font-weight: bold; font-family: "Open Sans"; color: $black; padding-left: 0px; margin: 30px 0px 40px; }
                }
            }
            &.col-two { float: left; width: 50%; @include boxSizing(border-box);
                &::after{clear:both;display: block;content:" "}
                &.col-padding-left { padding-left: 20px; }
                &.col-padding-right { padding-right: 20px; }
            }   
            &.manajemen-list , &.article-list {
                margin: 100px 0px; position: relative; display: block;
                .list {
                    position: relative; margin-bottom: 64px;
                    .image { float: left; width: 50%; @include boxSizing(border-box); padding-right: 20px; }
                    .desc {
                        float: right; width: 50%; @include boxSizing(border-box); padding-top: 35px; padding-left: 20px; position: relative;
                        &::before { content: ''; position: absolute; top: 0px; left: 20px; background-color: $black; width: 55px; height: 3px; } 
                        b { display: block; font-size: 18px; }
                        p.title { 
                            display: block; font-size: 18px; color: $red; margin-bottom: 20px; 
                            &:after {display: none;}
                        }
                        p {
                            position: relative;
                            &::after { content:"..."; position: relative; }
                        }
                        
                    }
                    .data-popup { display: none; }
                    &::after{clear:both;display: block;content:" "}
                }
            }
            &.article-list {
                .list {
                    .desc {
                        padding-top: 18px;
                        span.date { font-size: 12px; margin-bottom: 18px; font-style: italic; display: block; }
                        a.title { font-weight: 600; display: block; font-size: 16px; line-height: 24px; color: $black; margin-bottom: 18px; }
                        &::before { display:none; }
                    }
                }
            }
            &.cont-padding {
                position: relative;
                &.lembaga { width: 85%; }
            }
            &.recent-product-list {
                float: left; width: 32%; @include boxSizing(border-box); margin-right: 2%;
                &:nth-child(n+4) { margin-right: 0px; }
                &:nth-child(n+5) { display: none; }
                .content {
                    margin-top: 32px;
                    i.date { margin-bottom: 10px; display: block; }
                    a.title { display:block; font-size: 16px; line-height: 24px; font-weight: bold; color: $black; margin-bottom: 32px; }
                }
            }
            &.project-list , &.project-list-full , &.project-list-full-2  {
                position: relative; float: left; width: 48%; height: 370px; background-size: cover; @include boxSizing(border-box); margin-right: 3.5%; margin-bottom: 35px;
                &:nth-child(odd) { margin-right: 0px; }
                &:after{clear:both;display: block;content:" "}
                .inner , .innerVideo { position: relative; width: 100%; height: 100%; background-color: rgba(0,0,0,0.4); }
                .video-trigger { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: transparent; z-index: 2; }
                .innerText { position: absolute; top: 50%; left: 50%; padding:20px 40px; background-color: transparent; transform: translate(-50%, -50%); @include boxSizing(border-box); font-size: 30px; font-weight: bolder; color: $white;
                }
                span.play { display: block; width: 106px; height: 106px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background-image: url('../img/material/play.png'); @include transition(all .2s ease-in-out); background-repeat: no-repeat; background-size: 100%;
                    &:hover { width:88px; height: 88px; }
                }
                &:hover {
                    .inner { background-color: transparent; }
                    .innerText { background-color: $red; }
                }
                iframe { position: relative; width: 100%; height: 100%; display: none; }
            }
            &.project-list-full {
                width: 100%; height: 240px;
            }
            &.project-list-full-2 {
                width: 100%; height: 430px;
            }
            &.project-list-2 {
                padding: 37px 0px; position: relative; margin-bottom: 58px;
                div.bg-red { z-index: -1; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background-image: url("../img/material/bg-red.png"); background-size: auto 100%; background-repeat: no-repeat; }
                div.bg-white { z-index: -1; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background-image: url("../img/material/bg-white.png"); background-size: auto 100%; background-repeat: no-repeat; }
                .content { 
                    .inner { width: 230px; position: absolute; top: 60%; resize: vertical; transform: translateY(-60%); padding-left: 56px;
                        p.category { font-size: 12px; line-height: 18px; }
                        p.title { font-size: 28px; font-weight: bold; }
                    }
                }
                div.image { padding-left: 297px; }
            }
            &.video { width: 100%; height: 430px; position: relative; 
                .thumbnail { width: 100%; height: 100%; background-size: cover; position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
                span.play { cursor: pointer; display: block; width: 106px; height: 106px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background-image: url('../img/material/play.png'); @include transition(all .2s ease-in-out); background-repeat: no-repeat; background-size: 100%;
                    &:hover { width:88px; height: 88px; }
                }
                iframe { width: 100%; height: 430px; }
            }
            &.project { padding: 80px 0px; }
            &.project-1 { padding: 35px 0px; }
        }
        section {
            &.no-banner { padding-top: 199px; }
            &.thankyou { position: relative; min-height: 100vh; background-size: cover !important; 
                .inner {
                    position: absolute; top: 50%; transform: translateY(-50%); resize: vertical; left: 0px; right: 0px;
                    h1 { margin-bottom: 40px; }
                    p { max-width: 250px; }
                }
            }
            &.banner { position: relative; width: 100%; height: 411px; background-size: cover; top: 123px; }
            &.popup {
                display:none; position: fixed; top: 0px; left: 0px; bottom: 0px; top: 0px; background-color: rgba(0,0,0,0.4); width: 100%; height: 100vh; z-index: 1005;
                .popup-content {
                    position: absolute; top: 20%; left:0px; right: 0px; margin: auto; resize: vertical; transform: translateY(-20%); width: 971px; height: auto; background-color: $white;
                    .wrap { position: relative;
                        .image { float: left; width: 50%; @include boxSizing(border-box); padding-right: 56px; line-height: 0;
                            img { line-height: 0; width: 100%; }
                        }
                        .desc { position: relative; float:right; width: 50%; @include boxSizing(border-box); margin-top: 74px; padding-top: 41px; padding-left: 15px; padding-right: 70px; margin-bottom: 60px;
                            &::before { content: ''; position: absolute; top: 0px; left: 20px; background-color: $black; width: 55px; height: 3px; } 
                            b { display: block; font-size: 18px; }
                            p.title { display: block; font-size: 18px; color: $red; margin-bottom: 20px; }
                            p { color: $grey3; }
                        }
                    }
                    span.close { cursor: pointer; width: 12px; height: 12px; position: absolute; top: 25px; right: 22px; background-image: url('../img/material/close.png'); display: block; }
                    .container-arrow {
                        position: absolute; bottom: 0px; right: 0px;
                        div.btn-arrow { position: relative; width: 60px;height: 60px; border: 1px solid $black; float: left; cursor: pointer;
                            &:hover { background-color:$red; border: 1px solid $red; 
                                &.prev , &.next { span { background-position: right; } }
                            }
                            span { display: inline-block; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; margin: auto; height: 18px; width: 10px; background-repeat: no-repeat; }
                            &.prev {
                                right: -1px;
                                span { background-image: url('../img/material/arrow-slider-popup-prev.png'); }
                            }
                            &.next {
                                span { background-image: url('../img/material/arrow-slider-popup-next.png'); }
                            }
                        }
                    }
                }
            }
            &.content { margin-top: 92px; margin-bottom: 177px; top: 123px;
                div.wrapper { position: relative; width: 1070px; }
                div.wrapper-2 { position: relative; width: 1270px; 
                    div.content { margin-right: 200px; 
                        &.ikhtisar {
                            table {
                                thead {
                                    th:first-child { width: 290px; }
                                }
                            }
                        }
                    }
                }
                figure {
                    &.struktur { margin: 120px 0px 0px; }
                }
                aside {
                    float: left; width: 260px; padding-top: 52px; z-index: 10; position: relative;
                    &::before { content:''; position:absolute; top: 0px; left: 0px; height: 2px; width: 50px; background-color: $red; }
                    ul {
                        padding: 0px;
                        li {
                            padding: 0px;
                            &.active {
                                > a { color: $red; font-weight: 600; }
                                > .ctrl-dropdown {
                                    &::before { @include rotation(90deg); background-color: $red; z-index: 10; }
                                    &::after { @include rotation(90deg); background-color: transparent; }
                                }
                            }
                            a { line-height: 38px; font-size: 16px; color: $grey4; 
                                &:hover , &.active { color:$red; }
                            }
                            .ctrl-dropdown { cursor:pointer; position: absolute; right: 0px; top: 9px; display: block; width:20px; height: 20px;
                                &::before { content:""; position:absolute; width: 2px; height: 10px; background-color: $grey4; left: 9px; top: 5px; @include transition(all .4s ease-out); }
                                &::after { content:""; position:absolute; width: 10px; height: 2px; background-color: $grey4; top: 9px; left: 5px; @include transition(all .4s ease-out); }
                                &.active {
                                    &::before { @include rotation(90deg); background-color: $red; z-index: 10; }
                                    &::after { @include rotation(90deg); background-color: transparent; }
                                }
                            }
                            ul.child {
                                padding-left: 20px; margin: 15px 0px; display: none;
                                li {
                                    padding: 5px 15px; margin: 0px; position: relative;
                                    a { line-height: 16px; }
                                    &::before { content: ""; display: block; position: absolute; top: 0px; left: 0px; height:100%; max-height: 40px; width: 1px; background-color: $grey; }
                                    &:hover , &.active {
                                        > a { color:$red; }
                                        &::before { background-color: $black; }
                                    }
                                }
                                > li.hasChild {
                                    ul.child { padding-left: 0px; 
                                        li { padding: 0px; line-height: 24px;
                                            a { font-size: 13px; }
                                            &::before { display:none; }
                                        }
                                    }
                                }
                            }
                            &::before { display: none; }
                        }
                    }
                }
                article {
                    &.style-2 { padding-top: 71px; }
                    position: relative; margin-left: 300px; z-index: 1;
                }
                 ul , ol {
                    position: relative; padding-left: 14px; margin-bottom: 30px;
                    li { position: relative; padding-left: 22px; font-size: 16px; line-height: 30px; margin-bottom: 10px; 
                        &::before { content:''; position: absolute; top: 10px; left: 0px; width: 8px; height: 8px; @include borderRadius(10px); background-color: $red; }
                    }
                }
            }
        }
    }
}


/* footer
----------------------------------------------------------------------------------------------*/
footer {
    padding: 35px 0px 30px; background-color: $red2; color: $white;
    .wrapper { width: 1370px; }
    .logo { float:left; margin: 32px 0px; margin-right: 138px; }
    .address { float:left; margin: 0px 0px; margin-right: 138px; }
    .menu-socmed {
        max-width: 501px; float: right;
        .socmed {
            margin:20px 0px; width: 100%; display: block;
            div { float: left; margin-right: 31px; }
        }
        ul.menu {
            margin: 20px 0px;
            li { float: left; margin-right: 15px;
                a { text-transform: uppercase; }
            }
            &::after { content: ''; clear: both; display: block; }
        }
    }
    a { color: $white; font-size: 12px;
        &:hover { color:$white; }
    }
    p { font-size: 12px !important; line-height: 20px; 
        &.copyright { line-height: 12px; }
    }
}


@media screen and(min-width: 1300px){
    body {
        &.home {
            section {
                &.project {
                    .slider-project-nav { 
                        z-index: 100; float: right; position: absolute; top: 50%; transform: translateY(-50%); right: 60px !important; padding: 40px 0px 22px 0px;
                        &::after { position:absolute; top: 0px; bottom: 0px; right:12px; width: 1px; background:$red2; content: ''; }
                        .list { cursor:pointer; position:relative;display:block; clear:both; height: 40px; width: 40px !important; background: $white; color: $black; font-size: 12px; font-weight: bold; margin: 20px 0px; 
                                &::after { content:''; @include transition(all .2s ease-out); @include borderRadius(10px); position:absolute; right: 8px; top: 2px; width: 8px; height: 8px; background: $red2; }
                            &.slick-current {
                                &::after { width:15px; height: 15px; right: 4px; top:-2px; }
                            }
                        }
                    }
                }
            }
        }
    }
}